import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSpring, animated } from 'react-spring';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import homeScreen from '../assets/images/home-screen.webp';
import homeButton from '../assets/images/home-button.webp';
import homeButtonHover from '../assets/images/home-button-hover.webp';
import homeWalletCount from '../assets/images/home-wallet-count.webp'
import built0 from '../assets/images/built-0.webp';
import built1 from '../assets/images/built-1.webp';
import built1Line from '../assets/images/built-1-line.webp';
import built2 from '../assets/images/built-2.webp';
import built2Line from '../assets/images/built-2-line.webp';
import built3 from '../assets/images/built-3.webp';
import built3Line from '../assets/images/built-3-line.webp';
import built4 from '../assets/images/built-4.webp';
import built4Line from '../assets/images/built-4-line.webp';
import startBuildingUFO from '../assets/images/start-building-ufo.webp';
import startBuildingCoin from '../assets/images/start-building-coins.webp';
import iconCopy from '../assets/images/icon-copy.svg';
import iconExternalLink from '../assets/images/icon-external-link.svg';
import iconDiscord from '../assets/images/icon-discord.webp';
import iconMedium from '../assets/images/icon-medium.webp';
import iconTelegram from '../assets/images/icon-telegram.webp';
import iconX from '../assets/images/icon-x.webp';
import allAboardDoge from '../assets/images/all-aboard-space-doge.webp';
import allAboardBtn1 from '../assets/images/all-aboard-btn-1.webp';
import allAboardBtn1On from '../assets/images/all-aboard-btn-1-on.webp';
import allAboardBtn2 from '../assets/images/all-aboard-btn-2.webp';
import allAboardBtn2On from '../assets/images/all-aboard-btn-2-on.webp';
import allAboardBtn3 from '../assets/images/all-aboard-btn-3.webp';
import allAboardBtn3On from '../assets/images/all-aboard-btn-3-on.webp';
import allAboardScreen1 from '../assets/images/all-aboard-screen-1.webp';
import allAboardScreen2 from '../assets/images/all-aboard-screen-2.webp';
import allAboardScreen2Spaceship from '../assets/images/all-aboard-screen-2-spaceship.webp';
import allAboardScreen2Petrol from '../assets/images/all-aboard-screen-2-petrol.webp';
import allAboardScreen3 from '../assets/images/all-aboard-screen-3.webp';
import gameBtfd from '../assets/images/game-btfd-png.png';
import gameCD from '../assets/images/game-cd-png.png';
import gameConsole from '../assets/images/game-console.webp';
import gameChooChoo from '../assets/images/game-choochoo-png.png';
import gameEVM from '../assets/images/game-evm-png.png';
import gamePK from '../assets/images/game-pk-png.png';
import gameSpaceship from '../assets/images/game-spaceship.webp';
import gameSugarrush from '../assets/images/game-sugarrush-png.png';
import gameKittySlash from '../assets/images/game-kittyslash-png.png';
import logoIcandy from '../assets/images/logo-icandy.webp';
import logoZksync from '../assets/images/logo-zksync.webp';

function AnimatedNumber({ n }) {
    const prevN = useRef(n);
    const { number } = useSpring({
        from: { number: prevN.current },
        to: { number: n },
        config: {
            duration: 1000,  // Increased duration to 10 seconds
            easing: t => t * (2 - t),  // Ease-out function for smoother deceleration
        },
        onRest: () => {
            prevN.current = n;
        },
    });
    return <animated.span>{number.to((n) => Math.floor(n).toLocaleString())}</animated.span>;
}

export default function Home() {

    const [currentBuilt, setCurrentBuilt] = useState('');
    const [currentScreen, setCurrentScreen] = useState('1');
    const [totalWallets, setTotalWallets] = useState(0);

    const builtHover = (zook) => {
        setCurrentBuilt(zook);
    }

    const setScreen = (screen) => {
        setCurrentScreen(screen);
    }

    const copyCode = () => {
        navigator.clipboard.writeText('npm install candy-sdk');
        toast.success('Code copied!');
    }

    async function sendRequest(data, url, method = "post", params = null) {
        const config = {
            method,
            url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data),
        };
        if (params) config.params = params;
        return await axios.request(config).catch(function (error) {
            if (error.response) {
                return error.response;
            } else {
                return error;
            }
        });
    }

    const fetchTotalWallets = async () => {
        try {
            const response = await sendRequest(
                { action: "getTotalUsers" },
                `${process.env.REACT_APP_API_URL}`
            );

            console.log(response)
            if (response.status === 200 && response.data) {
                setTotalWallets(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching total wallets:", error);
        }
    };

    useEffect(() => {
        fetchTotalWallets(); // Initial fetch

        // Set up interval to fetch total wallets every 20 seconds
        const intervalId = setInterval(fetchTotalWallets, 20000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const games = [
        {
            img: gameChooChoo,
            name: '',
            link: 'https://choochoospirits.io/',
        },
        {
            img: gameBtfd,
            name: '',
            link: 'https://btfd.wtf/',
        },
        {
            img: gameEVM,
            name: '',
            link: 'https://evmwarfare.com/',
        },
        {
            img: gamePK,
            name: '',
            link: 'https://t.me/pepe_kingdom_bot',
        },
        {
            img: gameCD,
            name: '',
            link: 'https://t.co/sdeQCGrSXb',
        },
        {
            img: gameSugarrush,
            name: '',
            link: 'https://sugarrush-testnet.zkcandy.io/',
        },
        {
            img: gameKittySlash,
            name: '',
            link: 'https://t.me/kittyslash_bot',
        },
    ]

    const social = [
        {
            img: iconDiscord,
            link: 'https://discord.com/invite/zkcandy'
        },
        {
            img: iconX,
            link: 'https://x.com/ZKcandyHQ'
        },
        {
            img: iconTelegram,
            link: 'https://t.me/zkcandy'
        },
        {
            img: iconMedium,
            link: 'https://zkcandy.medium.com/'
        },
    ]

    return (
        <div className='zkcandy'>
            <ToastContainer theme='dark' />
            <main>
                <section id='homepage' className='relative flex flex-rows items-end'>
                    <div className='home-screen lg:relative max-w-[2000px] mx-auto'>
                        <img src={homeScreen} />

                        <div className='network-status absolute lg:bottom-[30%] lg:right-[16%] md:bottom-[32%] md:right-[20%] md:left-auto md:top-auto md:translate-x-[0] left-[50%] translate-x-[-50%] top-[4rem] no-wrap'>
                            <div className='text-primary font-black  text-[1rem] whitespace-nowrap'>
                                Network Status: Online
                            </div>
                        </div>
                        <div className='screen-text absolute -translate-x-[50%] -translate-y-[50%] left-[50%] md:top-[40%] top-[55%] w-[100%]'>
                            <div className="counter text-center relative mb-5 opacity-80">
                                <img className='m-auto 2xl:max-w-[800px] max-w-[500px] w-[80%] absolute -translate-x-[50%] -translate-y-[50%] left-[50%] top-[50%]' src={homeWalletCount} />
                                <div className="wallet-counts 2xl:text-[8rem] md:text-[5rem] text-[3rem] text-[#f643ff]">
                                    <AnimatedNumber n={totalWallets} />
                                </div>
                            </div>
                            <div className='font-black text-center uppercase leading-none lg:text-[#4D1177] md:text-[2.8rem] text-[2.3rem] md:text-white text-[#4D1177] lg:mb-auto mb-10'>
                                Unleash the Power <br />of ZK-Rollups <br /> for Gaming
                            </div>
                            <div className='text-center font-semibold text-[#4D1177] md:text-white  lg:text-[#4D1177] italic leading-1 mt-4 text-[1rem] px-5 md:px-auto max-w-[500px] mx-auto'>
                                ZKcandy unlocks lightning-fast, low-cost gaming on Ethereum. Build and play the next generation of Web3 games with true ownership.
                            </div>

                            <a href='#home-button-target' className="btn btn-dotted text-[1.6rem] text-center w-[200px]">
                                Start <br />Building
                            </a>
                        </div>

                        <a href='#home-button-target' className='home-button'>
                            <img src={homeButton} className='main' />
                            <img src={homeButtonHover} className='hover' />
                        </a>
                    </div>
                </section>

                <section id='built-for-web3'>
                    <div className='marquee pt-10'>
                        <ul className='marquee__content'>
                            <li><span className='circle'>⬤</span>Built for Web3 Gaming</li>
                            <li><span className='circle'>⬤</span>Built for Web3 Gaming</li>
                            <li><span className='circle'>⬤</span>Built for Web3 Gaming</li>
                        </ul>
                        {/* <!-- Mirrors the content above --> */}
                        <ul className='marquee__content' aria-hidden='true'>
                            <li><span className='circle'>⬤</span>Built for Web3 Gaming</li>
                            <li><span className='circle'>⬤</span>Built for Web3 Gaming</li>
                            <li><span className='circle'>⬤</span>Built for Web3 Gaming</li>
                        </ul>
                    </div>

                    <div id="home-button-target">
                        <div className='button-groups flex flex-wrap md:flex-nowrap gap-4 w-full justify-center pt-20 relative z-10 px-5'>
                            <button className='btn btn-purple-shadow animate-gradient lg:w-[200px] md:w-[25%]' onMouseEnter={() => builtHover('zook-1')} onMouseLeave={() => builtHover('')}>
                                Scale Your Game
                            </button>
                            <button className='btn btn-purple-shadow animate-gradient lg:w-[200px] md:w-[25%]' onMouseEnter={() => builtHover('zook-2')} onMouseLeave={() => builtHover('')}>
                                Onboard Players Effortlessly
                            </button>
                            <button className='btn btn-purple-shadow animate-gradient lg:w-[200px] md:w-[25%]' onMouseEnter={() => builtHover('zook-3')} onMouseLeave={() => builtHover('')}>
                                Build <br />Faster
                            </button>
                            <button className='btn btn-purple-shadow animate-gradient lg:w-[200px] md:w-[25%]' onMouseEnter={() => builtHover('zook-4')} onMouseLeave={() => builtHover('')}>
                                Enhance <br /> Your Gaming Experience
                            </button>
                        </div>

                        <div className={`zook-action ${currentBuilt}`}>
                            {currentBuilt === '' && (
                                <div className="zook zook-default">
                                    <div className="zook-text">
                                        <div className="main-text">
                                            Built for Web3 Gaming
                                        </div>
                                    </div>
                                    <img src={built0} />
                                </div>
                            )}
                            <div className='zook zook-1'>
                                <div className='zook-text'>
                                    <div className='main-text text-right'>Scale Your <br />Games</div>
                                    <div className='sub-text text-right'>
                                        Break free from Ethereum's limitations. ZKcandy delivers the speed and scalability your games need, all while keeping fees low and accessible for every player.
                                    </div>
                                </div>
                                <img className='zook-line' src={built1Line} />
                                <img src={built1} />
                            </div>
                            <div className='zook zook-2'>
                                <div className='zook-text'>
                                    <div className='main-text text-right'>Onboard Players <br />Effortlessly</div>
                                    <div className='sub-text text-right'>
                                        Welcome players with open arms. Candy Passport streamlines onboarding, replacing complex wallet setups with user-friendly logins through Abstraction.
                                    </div>
                                </div>
                                <img className='zook-line' src={built2Line} />
                                <img src={built2} />
                            </div>
                            <div className='zook zook-3'>
                                <div className='zook-text'>
                                    <div className='main-text text-left'>Build <br />Faster</div>
                                    <div className='sub-text text-left'>
                                        Seamlessly integrate NFTs and blockchain features into your games with our intuitive Candy API. Spend less time on tech, more time on crafting unforgettable experiences.
                                    </div>
                                </div>
                                <img className='zook-line' src={built3Line} />
                                <img src={built3} />
                            </div>
                            <div className='zook zook-4'>
                                <div className='zook-text'>
                                    <div className='main-text text-right'>Enhance Your Gaming <br />Experience</div>
                                    <div className='sub-text text-left'>
                                        Give players the freedom they deserve. With sponsored gas fees and gas abstraction-assisted flexible token payment options, your games become more accessible and enjoyable for everyone.
                                    </div>
                                </div>
                                <img className='zook-line' src={built4Line} />
                                <img src={built4} />
                            </div>
                        </div>
                    </div>
                </section>

                <section id='start-building' className='flex justify-center items-center flex-col p-10'>
                    <div className='relative'>
                        <img className='absolute -left-[10%] md:w-[200px] w-[90px] md:-top-[23svh] -top-[15svh]' src={startBuildingCoin} />
                        <img className='absolute translate-x-[-50%] left-[50%] md:-top-[23svh] -top-[15svh]' src={startBuildingUFO} />
                        <img className='absolute -right-[10%] md:w-[200px] w-[90px] scale-x-[-1] md:-top-[23svh] -top-[15svh]' src={startBuildingCoin} />

                        <div className='title font-black md:text-[4rem] text-[1.8rem] text-white uppercase relative'>
                            Start Building
                        </div>

                        <div className='code flex justify-between items-center w-full max-w-[600px] mt-5 cursor-pointer relative' onClick={copyCode}>
                            <div className='uppercase md:text-[1rem] text-[.7rem]'>npm install candy-sdk</div>
                            <img src={iconCopy} className='md:w-[20px] w-[12px]' />
                        </div>

                        <div className="grid grid-cols-2 w-full gap-5 mt-5 max-w-[600px] md:h-[200px] h-[150px] relative">
                            <a href='https://docs.zkcandyapi.com/' target='_blank' className="code flex flex-col justify-between">
                                <img className='ms-auto md:w-[18px] w-[10px]' src={iconExternalLink} />
                                <div className='uppercase md:text-[1rem] text-[.7rem]'>Docs</div>
                            </a>
                            <a href='https://app.zkcandyapi.com/login' target='_blank' className="code flex flex-col justify-between">
                                <img className='ms-auto md:w-[18px] w-[10px]' src={iconExternalLink} />
                                <div className='uppercase md:text-[1rem] text-[.7rem]'>Go No Code</div>
                            </a>
                        </div>
                    </div>
                </section>

                <section id="all-aboard">
                    <div className="container mx-auto">
                        <div className="grid md:grid-cols-2 grid-cols-1 items-center lg:max-w-[80%] md:max-w-[90%] m-auto pt-10">
                            <div>
                                <img className='md:w-full w-[90%] m-auto' src={allAboardDoge} />
                            </div>
                            <div>
                                <div className='lg:max-w-[500px] md:max-w-[300px] m-auto'>
                                    <div className='font-black uppercase lg:text-[3rem] md:text-[2rem] text-[1.1rem] text-white md:text-right text-center leading-none mb-2'>All Aboard the Testnet Voyage</div>
                                    <div className='text-white md:text-right text-center italic lg:text-[1.2rem] md:text-[.9rem] text-[0.8rem] mx-5 md:mx-0'>Explore our Testnet, complete quests, and earn rewards for the Mainnet launch.</div>
                                    <div className="box-highlight italic text-center font-medium mt-5 lg:ms-10 lg:text-[1rem] md:text-[.9rem] text-[.7rem] ms-5 me-5 md:me-0">
                                        <span className='font-black uppercase lg:text-[1.4rem] text-[1rem]'>Connect now </span> and explore our Voyage quests to earn points towards future airdrops upon Mainnet launch.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`all-aboard-tv flex lg:max-w-[80%] md:max-w-[90%] m-auto screen-${currentScreen} md:mt-10 mt-20`}>
                            <div className="buttons flex lg:flex-col md:flex-row justify-center">
                                <div className="cursor-pointer tv-button btn-1" onClick={() => setScreen('1')}>
                                    <img src={allAboardBtn1} />
                                    <img className='selected' src={allAboardBtn1On} />
                                </div>
                                <div className="cursor-pointer tv-button btn-2" onClick={() => setScreen('2')}>
                                    <img src={allAboardBtn2} />
                                    <img className='selected' src={allAboardBtn2On} />
                                </div>
                                <div className="cursor-pointer tv-button btn-3" onClick={() => setScreen('3')}>
                                    <img src={allAboardBtn3} />
                                    <img className='selected' src={allAboardBtn3On} />
                                </div>
                            </div>
                            <div className="screens relative">
                                <div className="screen1">
                                    <div className="screen-content">
                                        <img src={allAboardScreen1} />
                                        <div className='absolute -translate-x-[50%] -translate-y-[50%] left-[50%] top-[50%] flex flex-col items-center'>
                                            <div className='uppercase font-black xl:text-[3rem] md:text-[2rem] text-[1.5rem] text-center leading-none text-nowrap'>Chart Your <br />Course !</div>
                                            <a href='https://portal.zkcandy.io/' target='_blank' className='btn-dotted text-center flex-col justify-center leading-none mt-5'>
                                                <div className="left-dot"></div>
                                                <div className="right-dot"></div>
                                                <span className='xl:text-[1.4rem] md:text-[1rem] text-[.6rem]'>Connect To</span>
                                                <span className='xl:text-[2rem] md:text-[1.5rem] text-[1rem]'>Testnet</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="screen2 absolute top-0">
                                    <div className="screen-content relative">
                                        <img src={allAboardScreen2} />
                                        <div className="flex absolute -translate-y-[50%] top-[50%] w-full lg:p-10 md:p-5">
                                            <div className="w-3/5 flex items-end">
                                                <img src={allAboardScreen2Spaceship} />
                                            </div>
                                            <div className="petrol w-2/5 flex flex-col relative justify-end items-center">
                                                <div className='font-black xl:text-[2rem] md:text-[1.4rem] text-center uppercase'>Fuel Your Journey!</div>
                                                <div className='relative flex justify-center items-center'>
                                                    <img className='' src={allAboardScreen2Petrol} />

                                                    <div className="flex flex-col absolute w-[80%]">
                                                        <a href='https://sepolia.bridge.zkcandy.io/bridge/' target='_blank' className='btn-dotted text-center flex-col justify-center leading-none w-[90%] m-auto'>
                                                            <div className="left-dot"></div>
                                                            <div className="right-dot"></div>
                                                            <span className='xl:text-[1rem] md:text-[.9rem] text-[.5rem] text-nowrap'>Connect To</span>
                                                            <span className='xl:text-[2rem] md:text-[1.5rem] text-[.8rem]'>Bridge</span>
                                                        </a>

                                                        <a href='https://faucet.zkcandy.io/' target='_blank' className='btn-dotted text-center flex-col justify-center leading-none xl:mt-5 md:mt-4 mb-3  mt-2 w-[90%] m-auto'>
                                                            <div className="left-dot"></div>
                                                            <div className="right-dot"></div>
                                                            <span className='xl:text-[1rem] md:text-[.9rem] text-[.5rem] text-nowrap'>Connect To</span>
                                                            <span className='xl:text-[2rem] md:text-[1.5rem] text-[.8rem]'>Faucet</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="screen3 absolute top-0">
                                    <div className="screen-content">
                                        <img src={allAboardScreen3} />
                                        <div className='absolute -translate-x-[50%] -translate-y-[50%] left-[50%] top-[50%] flex flex-col items-center'>
                                            <div className='uppercase font-black xl:text-[3rem] md:text-[2rem] text-center leading-none'>Begin Your <br />Journey!</div>
                                            <a href='https://portal.zkcandy.io/' target='_blank' className='btn-dotted text-center flex-col justify-center leading-none mt-5 xl:text-[2rem] md:text-[1.5rem]'>
                                                <div className="left-dot"></div>
                                                <div className="right-dot"></div>
                                                <span className=''>Visit</span>
                                                <span className=''>Portal</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="games-on-zkcandy" className='md:p-10 p-5 mt-20'>
                        <div className="container border-4 rounded-[1rem] mx-auto lg:p-20 md:p-10 p-5 bg-[#9747ff61]">
                            <div className='flex flex-row items-center'>
                                <img src={gameSpaceship} className='transform scale-x-[-1] ms-auto w-[25%]' />
                                <img src={gameConsole} className='md:px-10 w-[50%]' />
                                <img src={gameSpaceship} className='w-[25%]' />
                            </div>

                            <div className='uppercase text-white font-black text-center lg:text-[4.5rem] md:text-[3rem] text-[1.5rem] my-5 text-shadow-black'>Games On ZKcandy</div>

                            {/* <div className="games-section grid grid-cols-2 md:gap-6 gap-2 lg:max-w-[1000px] md:max-w-[700px] mx-auto">
                                <div className="col-span-2 lg:col-span-4 grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-6">
                                    {games.slice(0, 4).map((game, index) => (
                                        <a
                                            target='_blank'
                                            key={index}
                                            href={game.link}
                                            className="games-block aspect-square"
                                        >
                                            <img src={game.img} className="w-full h-full object-cover" />
                                            {game.name && (<div className="game-name">{game.name}</div>)}
                                        </a>
                                    ))}
                                </div>
                                <div className="col-span-2 lg:col-span-4 grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6 lg:w-3/4 mx-auto">
                                    {games.slice(4).map((game, index) => (
                                        <a
                                            target='_blank'
                                            key={index}
                                            href={game.link}
                                            className="games-block aspect-square"
                                        >
                                            <img src={game.img} className="w-full h-full object-cover" />
                                            {game.name && (<div className="game-name">{game.name}</div>)}
                                        </a>
                                    ))}
                                </div>
                            </div> */}
                            <div className="games-section">
                                <div className="flex flex-wrap md:justify-center justify-start">
                                    {games.map((game, index) => (
                                        <div className="md:w-1/4 w-1/2 md:p-3 p-2">
                                            <a
                                                target='_blank'
                                                key={index}
                                                href={game.link}
                                                className="games-block w-full flex  aspect-square"
                                            >
                                                <img src={game.img} className="w-full h-full object-cover" />
                                                {game.name && (<div className="game-name">{game.name}</div>)}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="backed-by" className='bg-white py-10 flex items-center'>
                    <div className="container mx-auto">
                        <div className='uppercase text-center lg:text-[4rem] md:text-[3rem] text-[2rem] text-primary font-black'>Backed By</div>

                        <p className='w-[90%] max-w-[900px] mx-auto italic lg:text-[1.125rem] md:text-[1rem] text-[.9rem] font-medium text-center md:leading-8 leading-6 mb-20'>ZKsync's cutting-edge tech with iCandy's gaming expertise creates the ultimate playground for Web3 gamers and developers. Together, we're building the first L2 ZK chain dedicated to gaming, unlocking a new era of fast, scalable, and affordable Web3 experiences.</p>

                        <div className="grid md:grid-cols-2 grid-cols-1 items-center mb-10">
                            <a href='https://zksync.io/' target='_blank' className='btn-dotted-white md:w-[300px] w-[250px] h-[100px] md:h-[140px] lg:ms-auto lg:me-20 mx-auto md:mb-0 mb-5'>
                                <div className="left-dot"></div>
                                <div className="right-dot"></div>
                                <img src={logoZksync} />
                            </a>

                            <div className='md:p-0 p-5 md:max-w-[500px]'>
                                <h6 className='uppercase text-primary font-black mb-4 md:text-[2rem] text-[1.8rem] md:text-left text-center'>ZKSync</h6>
                                <p className='italic lg:text-left text-justify font-medium md:pe-10 md:text-[1rem] text-[.9rem]'>
                                    ZKsync is cutting-edge zero-knowledge (ZK) technology to scale Ethereum and bring crypto to the mainstream — reaching millions of developers and billions of people needing a technological solution for achieving progress and prosperity. Deeply rooted in its mission to advance personal freedom for all, the ZKsync blockchain network makes digital self-ownership universally available. It is trustless, secure, reliable, censorship-resistant, privacy-preserving, hyperscalable, accessible, and sovereign.
                                </p>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-2 grid-cols-1 items-center pb-10">
                            <a href='https://www.icandy.io/' target='_blank' className='btn-dotted-white md:w-[300px] w-[250px] h-[100px] md:h-[140px] lg:ms-auto lg:me-20 mx-auto md:mb-0 mb-5'>
                                <div className="left-dot"></div>
                                <div className="right-dot"></div>
                                <img className='' src={logoIcandy} />
                            </a>

                            <div className='md:p-0 p-5 md:max-w-[500px]'>
                                <h6 className="uppercase text-primary font-black mb-4 md:text-[2rem] text-[1.8rem] md:text-left text-center">
                                    iCandy
                                </h6>
                                <p className='italic lg:text-left text-justify font-medium md:pe-10 md:text-[1rem] text-[.9rem]'>
                                    iCandy Interactive Limited (iCandy) stands as one of the major game development studios in Southeast Asia and Oceania. As a publicly listed company on the ASX, iCandy boasts over 650 full-time employees, operating gaming studios in Germany, Thailand, Malaysia, Singapore, and Australia. Noteworthy shareholders in iCandy encompass Animoca Brands, Singtel, SK Telecom, AIS, Baidu, and others.
                                </p>
                            </div>
                        </div>

                        {/* <div className="uppercase font-black text-primary text-center mt-20 text-[2.8rem]">Supported By</div> */}
                    </div>
                    {/* <div className='marquee pt-8'>
              <ul className='marquee__content'>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
              </ul>
              <ul className='marquee__content' aria-hidden='true'>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
                <li><img className='w-[250px] opacity-30' src={logoPoweredBy} /></li>
              </ul>
            </div> */}
                </section>

                {/* <section id="testnet-result">
                    <div className="flex align-center flex-col py-20 relative container m-auto">

                        <div className='uppercase text-center font-black'>
                            <div className="text-white lg:text-[3.5rem] md:text-[2rem] text-[1.5rem] mb-2">
                                Powered By Players
                            </div>
                            <div className="flex justify-center w-[80%] md:w-full md:mr-0 md:ml-auto mx-auto">
                                <div className="text-primary bg-white lg:text-[1.6rem] text-[.8rem] rounded-3xl px-5 py-2 whitespace-nowrap">
                                    5-Days Closed Testnet Results
                                </div>
                            </div>
                        </div>

                        <div className="flex text-white justify-evenly text-center mt-10 md:flex-row flex-col">
                            <div>
                                <div className='md:text-[3rem] text-[2rem] font-black'>41,825</div>
                                <div className='lg:text-[1rem] md:text-[.6rem] text-[.6rem] uppercase italic lg:mb-10 md:mb-5 mb-4'>Wallets Connected</div>
                            </div>

                            <div>
                                <div className='md:text-[3rem] text-[2rem] font-black'>16,830</div>
                                <div className='lg:text-[1rem] md:text-[.6rem] text-[.6rem] uppercase italic lg:mb-10 md:mb-5 mb-4'>Game Sessions Played</div>
                            </div>

                            <div>
                                <div className='md:text-[3rem] text-[2rem] font-black'>5,788</div>
                                <div className='lg:text-[1rem] md:text-[.6rem] text-[.6rem] uppercase italic '>
                                    Players On <br /> Leaderboards
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section id="stay-connected" className='flex'>
                    <div className="container mx-auto flex flex-col justify-center items-center">
                        <div className='uppercase text-white lg:text-[3.5rem] md:text-[2.5rem] text-[2rem] text-center font-black'>Stay Connected With Us</div>
                        <div className='text-white lg:text-[1.25rem] md:text-[1rem] text-[.9rem] md:px-0 px-6 italic font-medium text-center mt-4'>Get ZKcandy’s latest ecosystem updates delivered straight to your mailbox.</div>

                        {/* <div className='flex md:flex-row flex-col mt-8 items-center lg:w-[600px] md:w-[500px] w-[80%] md:mx-10'>
                <input className='stay-input md:w-2/3 w-full md:me-6 font-black uppercase' type="text" placeholder='example@gmail.com' />
                <button className='md:w-1/3 w-[50%] md:mt-0 mt-4 btn-dotted-white small p-0'>Subscribe</button>
              </div> */}
                        <div className="social grid md:grid-cols-4 grid-cols-2 md:gap-6 gap-10 mt-10">
                            {social.map((item, index) => (
                                <a href={item.link} key={index} target='_blank'>
                                    <img src={item.img} className='lg:w-[64px] md:w-[48px] w-[64px]' />
                                </a>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <footer>

            </footer>
        </div>
    );
}